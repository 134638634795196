.form {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ssoLink {
    width: fit-content;
    align-self: center;
  }

  :global(.sso-buttons) {
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    button {
      margin-top: 0;
    }

    a:global(.sign-in-okta-button) {
      color: black;
      background-color: white;
      border-radius: 4px;
      border: 1px solid black;
      padding: 8px 16px;
      text-align: center;
      text-decoration: none;

      &:hover {
        border: 1px solid black;
        color: black;
        background-color: #cecece;
      }
    }
  }

  & > label:nth-of-type(2) {
    margin-top: 1rem;
  }

  label {
    color: #6c6c6c;
    font-weight: 600;
  }

  input {
    width: 100%;
    height: 3.14em;
    box-sizing: border-box;
    padding: 11px 8px;
    margin-top: 8px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
  }

  a {
    margin-top: 4px;
    color: #1e749f;
  }

  button {
    width: fit-content;
    line-height: 2em;
    box-sizing: border-box;
    padding: 4px 16px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    align-self: center;
    margin-top: 18px;

    align-items: center;
    font-weight: 700;
    background-color: #2ca7e4;
    color: white;
    border: 0;
    vertical-align: middle;
    border-radius: 40px;

    &:disabled {
      background-color: rgb(168, 168, 168);
      color: white;
    }

    &:hover {
      cursor: pointer;
      filter: brightness(108%);
    }
  }
}

.forgotPassword {
  margin-bottom: 18px;
}

.googleLoginCta {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 32px;

  b {
    color: #6c6c6c;
    font-weight: 600;
  }

  align-self: center;
  margin-top: 32px;
}

.registerCta {
  text-align: center;
  margin-top: 16px;
  color: #6c6c6c;
  font-style: italic;

  a {
    margin-left: 0.5em;
    font-style: normal;
  }
}

.twoFactorMessage {
  margin-bottom: 16px;
}

.errorMessage {
  color: rgb(210, 106, 102);
  text-align: center;
  width: 100%;
}

.ssoButtons {
  .gsiMaterialButton {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-appearance: none;
    background-color: WHITE;
    background-image: none;
    border: 1px solid #747775;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #1f1f1f;
    cursor: pointer;
    font-family: 'Roboto', arial, sans-serif;
    font-size: 14px;
    height: 40px;
    letter-spacing: 0.25px;
    outline: none;
    overflow: hidden;
    padding: 0 12px;
    position: relative;
    text-align: center;
    -webkit-transition:
      background-color 0.218s,
      border-color 0.218s,
      box-shadow 0.218s;
    transition:
      background-color 0.218s,
      border-color 0.218s,
      box-shadow 0.218s;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    max-width: 400px;
    min-width: min-content;
  }

  .gsiMaterialButton .gsiMaterialButtonIcon {
    height: 20px;
    margin-right: 12px;
    min-width: 20px;
    width: 20px;
  }

  .gsiMaterialButton .gsiMaterialButtonContentWrapper {
    -webkit-align-items: center;
    align-items: center;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

  .gsiMaterialButton .gsiMaterialButtonContents {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    font-family: 'Roboto', arial, sans-serif;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }

  .gsiMaterialButton .gsiMaterialButtonState {
    -webkit-transition: opacity 0.218s;
    transition: opacity 0.218s;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .gsiMaterialButton:disabled {
    cursor: default;
    background-color: #ffffff61;
    border-color: #1f1f1f1f;
  }

  .gsiMaterialButton:disabled .gsiMaterialButtonContents {
    opacity: 38%;
  }

  .gsiMaterialButton:disabled .gsiMaterialButtonIcon {
    opacity: 38%;
  }

  .gsiMaterialButton:not(:disabled):active .gsiMaterialButtonState,
  .gsiMaterialButton:not(:disabled):focus .gsiMaterialButtonState {
    background-color: #303030;
    opacity: 12%;
  }

  .gsiMaterialButton:not(:disabled):hover {
    -webkit-box-shadow:
      0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
    box-shadow:
      0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
  }

  .gsiMaterialButton:not(:disabled):hover .gsiMaterialButtonState {
    background-color: #303030;
    opacity: 8%;
  }
}
